import axios, { AxiosInstance } from 'axios'
import store from '@/store'

const defaultOptions = {
  baseURL: `${process.env.VUE_APP_API_URL}`
}

const instance = axios.create(defaultOptions)

instance.interceptors.response.use(
  (response) => {
    if (response.data && response.data.errorCode > 0) {
      return Promise.reject(response)
    }
    return response
  }, (error) => {
    console.log(error.response)
    if (error.response.data.errorCode === 401 || error.response.status === 401) {
      store.dispatch('user/clear')
    } else {
      return Promise.reject(error.response)
    }
  }
)

export default instance

export const setAuthorizationHeader = (a: AxiosInstance) => {
  a.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken') || ''
    }`
}
