import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_MP3PLAYER, CLEAR_MP3PLAYER } from '@/store/mp3player/mutations'
import { Album } from '@/store/mp3player/types'
import { Mp3PlayerState } from "@/store/mp3player/state"
import { parseAxiosError } from '@/services/api'
import { getLibrary } from '@/store/mp3player/api-requests'
import { RootState } from '../state'

type MP3PlayerContext = ActionContext<Mp3PlayerState, RootState>

const getDefaultState = () => { 
  return {
    albums: [],
    lastUpdate: null
  }
}

const initialState: Mp3PlayerState = getDefaultState()

const getters: GetterTree<Mp3PlayerState, RootState> = {
  mp3player (state: Mp3PlayerState): Mp3PlayerState {    
    return state
  },
  albums (state: Mp3PlayerState): Album[] {    
    return state.albums
  },
  lastUpdate (state: Mp3PlayerState): Date|null {    
    return state.lastUpdate
  }
}

const mutations: MutationTree<Mp3PlayerState> = {
  [SET_MP3PLAYER] (state: Mp3PlayerState, albums: Album[]) {
    state.albums = albums
    state.lastUpdate = new Date(new Date().setHours(new Date().getHours() + 1))
  },
  [CLEAR_MP3PLAYER] (state: Mp3PlayerState) {
    Object.assign(state, getDefaultState())
  }
}

const actions: ActionTree<Mp3PlayerState, RootState> = {
  async loadLibrary ({ commit }: MP3PlayerContext): Promise<any> {
    try {      
      const albums = await getLibrary()
      commit(SET_MP3PLAYER, albums)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    }
  },
  
  async clear ({ commit, dispatch, state }: MP3PlayerContext): Promise<void> {
    try {
      commit(CLEAR_MP3PLAYER)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    }
  },
}

export const mp3player = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
