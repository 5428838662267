
import { Album, AudioFile } from "../../store/mp3player/types";
import { defineComponent, PropType } from "vue";
import moment from 'moment';
import AudioRating from "./AudioRating.vue";

export default defineComponent({
    components: { AudioRating },
    name: 'LibraryAlbum',
    emits: ['addToPlaylist'],
    props: {
        album: {
            type: Object as PropType<Album>,
            default: null
        }
    },
    data() {
        return {
            fullAlbum: false,
            clicks: 0,
            timer: 0
        }
    },
    computed: {
        audioFilesPreview(): Array<AudioFile> {
            if (this.album) {
                if (this.fullAlbum) {
                    return this.album.audioFiles
                }
                return this.album.audioFiles.filter((el: AudioFile) => el.state !== 1 && el.state !== 2).slice(0, 10)
            }
            return []
        }
    },
    methods: {
        selectAudioTrack(audioFile: AudioFile) {
            this.clicks++;
            if (this.clicks === 1) {
                this.timer = setTimeout(() => {
                    this.clicks = 0
                    audioFile.selected = !audioFile.selected
                }, 300);
            } else if (this.clicks === 2) {
                if (!audioFile.selected) {
                    audioFile.selected = true
                }
                clearTimeout(this.timer)
                this.clicks = 0
                this.$emit('addToPlaylist')
            } else {
                clearTimeout(this.timer)
                this.clicks = 0
            }
        },
        selectAudioAlbum(album: Album) {
            let select = false
            this.clicks++;

            if (this.clicks === 1) {
                this.timer = setTimeout(() => {
                    this.clicks = 0
                    album.audioFiles.forEach(audioFile => {
                        if (audioFile.state === 1 || audioFile.state === 2) {
                            audioFile.selected = false
                        }
                        if (!audioFile.selected) {
                            select = true
                        }
                    })
                    album.audioFiles.forEach(audioFile => {
                        audioFile.selected = select
                        if (select) {
                            if (audioFile.state === 1 || audioFile.state === 2) {
                                audioFile.selected = false
                            }
                        }
                    })
                }, 300);
            } else if (this.clicks === 2) {
                album.audioFiles.forEach(audioFile => {
                    audioFile.selected = true
                    if (audioFile.state === 1 || audioFile.state === 2) {
                        audioFile.selected = false
                    }
                })
                clearTimeout(this.timer)
                this.clicks = 0
                this.$emit('addToPlaylist')
            } else {
                clearTimeout(this.timer)
                this.clicks = 0
            }
        },
        getAudioFileLastDuration(duration: number | null): string {
            if (duration) {
                return Math.floor(duration / 60) + ':' + (duration % 60 < 10 ? '0' : '') + (duration % 60)
            }
            return ''
        },
        getAlbumDuration(album: Album): string {
            let duration = 0
            album.audioFiles.forEach(audioFile => {
                duration += +audioFile.duration
            })            
            if (duration) {
                return Math.floor(duration / 60) + ':' + (duration % 60 < 10 ? '0' : '') + (duration % 60)
            }
            return ''
        },
        getAudioFileLastPlayed(dateAsString: string | null): string {
            if (dateAsString) {
                return moment(dateAsString).format('DD.MM.YYYY HH:mm')
            }
            return ''
        }
    }
})
