import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_home_view = _resolveComponent("home-view")!
  const _component_login_view = _resolveComponent("login-view")!
  const _component_mp3_player = _resolveComponent("mp3-player")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_nav_bar, {
      userServices: _ctx.userServices,
      onRoute: _ctx.changeRoute
    }, null, 8, ["userServices", "onRoute"]),
    (_ctx.route == 'home')
      ? (_openBlock(), _createBlock(_component_home_view, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.route == 'login')
      ? (_openBlock(), _createBlock(_component_login_view, {
          key: 1,
          onRoute: _ctx.changeRoute
        }, null, 8, ["onRoute"]))
      : _createCommentVNode("", true),
    (_ctx.route == 'mp3player')
      ? (_openBlock(), _createBlock(_component_mp3_player, {
          key: 2,
          onRoute: _ctx.changeRoute
        }, null, 8, ["onRoute"]))
      : _createCommentVNode("", true)
  ], 64))
}