import { createStore } from 'vuex'
import { user } from '@/store/user'
import { mp3player } from '@/store/mp3player'
import { window } from '@/store/window'

export default createStore({
  modules: {
    user,
    mp3player,
    window
  }
})
