

enum State {
  Show = 0,
  Hidden = 1,
  GenreHidden = 2
}

import store from "@/store";
import { Album, AudioFile } from "@/store/mp3player/types";
import { defineComponent, reactive } from "vue";
import LibraryFilterView from "@/components/mp3player/LibraryFilterView.vue";
import LibraryAlbum from "@/components/mp3player/LibraryAlbum.vue";
import AudioPlayer from "@/components/mp3player/AudioPlayer.vue";
import { LibraryFilter, LibraryFilterValue } from "@/components/mp3player/mp3player-types";

export default defineComponent({
  components: { LibraryAlbum, AudioPlayer, LibraryFilterView },
  name: 'Mp3Player',
  data() {
    return {
      title: 'MP3',
      currentAudioFile: null,
      loading: false,
      updateProcess: false,
      cursorLoading: false,
      loadAlbums: [] as Album[],
      albums: [] as Album[],
      albumId: 0,
      audioList: reactive([] as AudioFile[]),
      file: null as any,
      selectedInList: null as any,
      timer: 0,
      info: '',
      yearFilter: {
        name: 'Год',
        values: []
      } as LibraryFilter,
      artistsFilter: {
        name: 'Артисты',
        values: []
      } as LibraryFilter,
      genresFilter: {
        name: 'Жанры',
        values: []
      } as LibraryFilter,
      ratingsFilter: {
        name: 'Рейтинги',
        values: []
      } as LibraryFilter
    }
  },
  methods: {
    gift() {
      console.log('gift')
      setTimeout(() => {this.cursorLoading = !this.cursorLoading},1000);
    },
    addToPlaylist() {
      this.albums.forEach(album => {
        album.audioFiles.forEach(audioFile => {
          if (audioFile.selected) {
            if (!this.audioList.find((el: AudioFile) => el.id === audioFile.id)) {
              this.audioList.push(Object.assign(audioFile))
            }
            audioFile.selected = false
          }
        });
      });
    },
    clearPlaylist() {
      this.audioList = []
    },
    changeTrack(value: number) {
      this.albums.forEach((album: Album) => {
        if (album.audioFiles.find((audioFile: AudioFile) => audioFile.id === value)) {
          this.albumId = album.id
        }
      })
    },
    selectValueFilter(value: LibraryFilterValue) {
      this.cursorLoading = true
      setTimeout(() => {this.changeFilter(value, false)},1); 
    },
    addValueFilter(value: LibraryFilterValue) {
      this.cursorLoading = true
      setTimeout(() => {this.changeFilter(value, false)},1);      
    },
    changeFilter(value: LibraryFilterValue, type: boolean) {
      if (value.value === 0) {
        switch (value.type) {
          case 'year':
            this.yearFilter.values.forEach(yearElement => {
              yearElement.selected = false
            });
            break;

          case 'artist':
            this.artistsFilter.values.forEach(artistElement => {
              artistElement.selected = false
            });
            break;

          case 'genre':
            this.genresFilter.values.forEach(genreElement => {
              genreElement.selected = false
            });
            break;

          case 'rating':
            this.ratingsFilter.values.forEach(ratingElement => {
              ratingElement.selected = false
            });
            break;

          default:
            break;
        }
      } else {
        switch (value.type) {
          case 'rating':
            this.ratingsFilter.values.forEach((el: LibraryFilterValue) => {
              if (el.value == value.value) {
                el.selected = !el.selected
              } else {
                if (type) {
                  el.selected = false
                }
              }
            })
            break;
          case 'year':
            this.yearFilter.values.forEach((el: LibraryFilterValue) => {
              if (el.value == value.value) {
                el.selected = !el.selected
              } else {
                if (type) {
                  el.selected = false
                }
              }
            })
            break;

          case 'artist':
            this.artistsFilter.values.forEach((el: LibraryFilterValue) => {
              if (el.value == value.value) {
                el.selected = !el.selected
              } else {
                if (type) {
                  el.selected = false
                }
              }
            })
            break;

          case 'genre':
            this.genresFilter.values.forEach((el: LibraryFilterValue) => {
              if (el.value == value.value) {
                el.selected = !el.selected
              } else {
                if (type) {
                  el.selected = false
                }
              }
            })
            break;

          default:
            break;
        }
      }
      this.updateAlbums(this.loadAlbums)
    },
    filterHasSelected(filter: LibraryFilter) {
      if (filter.values.find((el: LibraryFilterValue) => el.selected == true)) {
        return true
      }
      return false
    },
    async loadLibrary() {
      if (!store.getters['mp3player/lastUpdate']) {
        this.loading = true
        this.info = 'Загружаем....'
        store.dispatch('mp3player/loadLibrary')
          .then(() => {
            return store.getters['mp3player/albums']
          })
          .then(async (albums: Album[]) => {
            this.loadAlbums = albums
            await this.createFilters(this.loadAlbums)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loadAlbums = store.getters['mp3player/albums']
        this.createFilters(this.loadAlbums)
      }
    },
    async createFilters(albums: Album[]) {
      let yearFilter: LibraryFilter = {
        name: 'Год',
        values: []
      }

      let artistsFilter: LibraryFilter = {
        name: 'Артисты',
        values: []
      }

      let genresFilter: LibraryFilter = {
        name: 'Жанры',
        values: []
      }

      let ratingsFilter: LibraryFilter = {
        name: 'Рейтинги',
        values: []
      }

      albums.forEach(async (album: Album) => {
        album.audioFiles.sort((a, b) => (a.trackNo < b.trackNo ? -1 : 1))

        const findedYearFilter = this.yearFilter.values.find((value: LibraryFilterValue) => value.value == album.year)

        if (!findedYearFilter) {
          if (!yearFilter.values.find((value: LibraryFilterValue) => value.value == album.year)) {
            const libraryFilterValue: LibraryFilterValue = {
              type: 'year',
              value: album.year,
              selected: false
            }
            yearFilter.values.push(libraryFilterValue)
          }
        } else {
          if (!yearFilter.values.find((value: LibraryFilterValue) => value.value == album.year)) {
            const libraryFilterValue: LibraryFilterValue = {
              type: 'year',
              value: album.year,
              selected: findedYearFilter.selected
            }
            yearFilter.values.push(libraryFilterValue)
          }
        }

        const findedArtistsFilter = this.artistsFilter.values.find((value: LibraryFilterValue) => value.value == album.artist)

        if (!findedArtistsFilter) {
          if (!artistsFilter.values.find((value: LibraryFilterValue) => value.value == album.artist)) {
            const libraryFilterValue: LibraryFilterValue = {
              type: 'artist',
              value: album.artist,
              selected: false
            }
            artistsFilter.values.push(libraryFilterValue)
          }
        } else {
          if (!artistsFilter.values.find((value: LibraryFilterValue) => value.value == album.artist)) {
            const libraryFilterValue: LibraryFilterValue = {
              type: 'artist',
              value: album.artist,
              selected: findedArtistsFilter.selected
            }
            artistsFilter.values.push(libraryFilterValue)
          }
        }

        album.audioFiles.forEach((af: AudioFile) => {

          const findedGenresFilter = this.genresFilter.values.find((value: LibraryFilterValue) => value.value == af.genre)

          if (!findedGenresFilter) {
            if (!genresFilter.values.find((value: LibraryFilterValue) => value.value == af.genre)) {
              const libraryFilterValue: LibraryFilterValue = {
                type: 'genre',
                value: af.genre,
                selected: false
              }
              genresFilter.values.push(libraryFilterValue)
            }
          } else {
            if (!genresFilter.values.find((value: LibraryFilterValue) => value.value == af.genre)) {
              const libraryFilterValue: LibraryFilterValue = {
                type: 'genre',
                value: af.genre,
                selected: findedGenresFilter.selected
              }
              genresFilter.values.push(libraryFilterValue)
            }
          }

          const findedRatingFilter = this.ratingsFilter.values.find((value: LibraryFilterValue) => value.value == af.rating)
          if (!findedRatingFilter) {
            if (!ratingsFilter.values.find((value: LibraryFilterValue) => value.value == af.rating)) {
              const libraryFilterValue: LibraryFilterValue = {
                type: 'rating',
                value: af.rating,
                selected: false
              }
              ratingsFilter.values.push(libraryFilterValue)
            }
          } else {
            if (!ratingsFilter.values.find((value: LibraryFilterValue) => value.value == af.rating)) {
              const libraryFilterValue: LibraryFilterValue = {
                type: 'rating',
                value: af.rating,
                selected: (findedRatingFilter !== undefined) ? findedRatingFilter.selected : false
              }
              ratingsFilter.values.push(libraryFilterValue)
            }
          }
        })
      });

      yearFilter.values.sort((a, b) => (a.value < b.value ? 1 : -1))
      this.yearFilter = yearFilter

      artistsFilter.values.sort((a, b) => (a.value < b.value ? -1 : 1))
      this.artistsFilter = artistsFilter

      genresFilter.values.sort((a, b) => (a.value < b.value ? -1 : 1))
      this.genresFilter = genresFilter

      ratingsFilter.values.sort((a, b) => (a.value < b.value ? 1 : -1))
      this.ratingsFilter = ratingsFilter
      this.updateAlbums(albums)
    },
    async updateAlbums(albumsBefore: Album[]) {

      let filteredAlbums = [] as Album[]
      let filtered = false

      if (this.filterHasSelected(this.yearFilter)) {
        albumsBefore.forEach((album: Album) => {
          if (this.yearFilter.values.find((value: LibraryFilterValue) => value.selected == true && value.value == album.year)) {
            filteredAlbums.push(album)
          }
        })
        filtered = true
      }

      if (this.filterHasSelected(this.artistsFilter)) {
        albumsBefore.forEach((album: Album) => {
          if (this.artistsFilter.values.find((value: LibraryFilterValue) => value.selected == true && value.value == album.artist)) {
            filteredAlbums.push(album)
          }
        })
        filtered = true
      }

      if (this.filterHasSelected(this.genresFilter)) {
        if (filtered) {
          let tempAlbums = [] as Album[]
          filteredAlbums.forEach((album: Album) => {
            let isInsertAlbum = false
            album.audioFiles.forEach((element: AudioFile) => {
              if (this.genresFilter.values.find((value: LibraryFilterValue) => value.selected == true && value.value == element.genre)) {
                isInsertAlbum = true
                element.state = State.Show
              } else {
                element.state = State.Hidden
              }
            })
            if (isInsertAlbum) {
              filteredAlbums.push(album)
            }
          })
          filteredAlbums = tempAlbums
        } else {
          albumsBefore.forEach((album: Album) => {
            let isInsertAlbum = false
            album.audioFiles.forEach((element: AudioFile) => {
              if (this.genresFilter.values.find((value: LibraryFilterValue) => value.selected == true && value.value == element.genre)) {
                isInsertAlbum = true
                element.state = State.Show
              } else {
                element.state = State.Hidden
              }
            })
            if (isInsertAlbum) {
              filteredAlbums.push(album)
            }
          })
        }
        filtered = true
      }

      if (this.filterHasSelected(this.ratingsFilter)) {
        if (filtered) {
          let tempAlbums = [] as Album[]
          filteredAlbums.forEach((album: Album) => {
            let isInsertAlbum = false
            album.audioFiles.forEach((element: AudioFile) => {
              if (this.ratingsFilter.values.find((value: LibraryFilterValue) => value.selected == true && value.value == element.rating)) {
                isInsertAlbum = true
                element.state = State.Show
              } else {
                element.state = State.Hidden
              }
            })
            if (isInsertAlbum) {
              tempAlbums.push(album)
            }
          })
          filteredAlbums = tempAlbums
        } else {
          albumsBefore.forEach((album: Album) => {
            let isInsertAlbum = false
            album.audioFiles.forEach((element: AudioFile) => {
              if (this.ratingsFilter.values.find((value: LibraryFilterValue) => value.selected == true && value.value == element.rating)) {
                isInsertAlbum = true
                element.state = State.Show
              } else {
                element.state = State.Hidden
              }
            })
            if (isInsertAlbum) {
              filteredAlbums.push(album)
            }
          })
        }
        filtered = true
      } else {
        albumsBefore.forEach((album: Album) => {
          album.audioFiles.forEach((element: AudioFile) => {
            element.state = State.Show
          })
        })
      }

      if (!filtered) {
        filteredAlbums = albumsBefore
      }

      filteredAlbums.sort(function (a, b) {

        if (a.year < b.year) return 1;
        if (a.year > b.year) return -1;

        if (a.artist < b.artist) return -1;
        if (a.artist > b.artist) return 1;

        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      });

      console.log('count albums: ' + filteredAlbums.length)      
      this.updateProcess = true
      this.albums = filteredAlbums
    }
  },
  mounted() {
    this.loadLibrary()
  },
  updated() {
    if (this.updateProcess) {
      this.cursorLoading = false
      this.updateProcess = false
    }    
  },
})
