

import { defineComponent, getCurrentInstance } from "vue";
import AudioRating from "./AudioRating.vue";
import { LibraryFilter, LibraryFilterValue } from "./mp3player-types";

export default defineComponent({
    components: { AudioRating },
    name: 'LibraryFilter',
    emits: ['selectValue', 'addValue'],
    props: {
        filterNameProp: {
            type: String,
            default: 'unknown'
        },
        filter: {
            type: Object,
            default(rawProps: LibraryFilter) {
                return {
                    name: rawProps.name,
                    values: []
                }
            }
        }
    },
    data() {
        return {
            allItem: {
                type: 'zero',
                value: 0
            } as LibraryFilterValue
        }
    },
    methods: {
        selectValue(value: LibraryFilterValue) {
            this.$emit('selectValue', value)
        },
        addValue(value: LibraryFilterValue) {
            this.$emit('addValue', value)
        }
    },
    mounted() {
        const ctx = getCurrentInstance()
        this.allItem.type = ctx?.props.filterNameProp
    },
})
