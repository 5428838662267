

export type PasswordParameters = {
    passwordLenght: number,
    passwordsCount: number,
    capitalLettersCheck: boolean,
    numbersCheck: boolean,
    specialCharactersCheck: boolean,
    specialCharacters: string,
    excludeCharactersCheck: boolean,
    excludeCharacters: string
}

import { defineComponent } from "vue";
export default defineComponent({    
    name: 'PasswordPanel',
    data() {
        return {
            alphabetBase: 'abcdefghijklmnopqrstuvwxyz',
            passwordParams: {
                passwordLenght: 8,
                passwordsCount: 1,
                capitalLettersCheck: true,
                numbersCheck: true,
                specialCharactersCheck: false,
                specialCharacters: '#@$%^&*',
                excludeCharactersCheck: false,
                excludeCharacters: 'I1lo0O'
            } as PasswordParameters,
            passwords: [] as Array<string>,
            loading: false
        }
    },
    methods: {
        generatePassword() {
            this.passwords = []
            for (let i = 0; i < this.passwordParams.passwordsCount; i++) {
                let password = ''
                let alphabet = this.alphabetBase
                if (this.passwordParams.capitalLettersCheck) {
                    const alphabetBaseArray = this.alphabetBase.split('')
                    alphabetBaseArray.forEach((element: string) => {
                        alphabet += element.toUpperCase()
                    });                    
                }
                if (this.passwordParams.numbersCheck) {
                    alphabet += '1234567890'
                }
                if (this.passwordParams.specialCharactersCheck) {
                    alphabet += this.passwordParams.specialCharacters
                }
                while (password.length < this.passwordParams.passwordLenght) {                    
                    const letter = alphabet[Math.round(Math.random() * (alphabet.length - 1))]
                    if (this.passwordParams.excludeCharactersCheck) {
                        const excludeCharactersArray = this.passwordParams.excludeCharacters.split('')
                        if (!excludeCharactersArray.find((el: string) => el == letter)) {
                            password += letter
                        }
                    } else {
                        password += letter
                    }                    
                }
                this.passwords.push(password)              
            }
        },
        checkPasswordLenght() {
            const value = Number(this.passwordParams.passwordLenght)
            if (Number.isInteger(value)) {
                if (value < 1) {
                    this.passwordParams.passwordLenght = 1
                } else if (value > 50) {
                    this.passwordParams.passwordLenght = 50
                }
            } else {
                this.passwordParams.passwordLenght = 1
            }
        },
        checkPasswordCount() {
            const value = Number(this.passwordParams.passwordsCount)
            if (Number.isInteger(value)) {
                if (value < 1) {
                    this.passwordParams.passwordsCount = 1
                } else if (value > 20) {
                    this.passwordParams.passwordsCount = 20
                }
            } else {
                this.passwordParams.passwordsCount = 1
            }
        }
    }
})
