

import { UserLoginPost } from '@/store/user/api-types'
import { login } from '@/store/user/api-requests'
import { defineComponent } from "vue";

export default defineComponent({
  name: 'LoginView',
  data() {
    return {
      userLoginPost: {
        username: '',
        password: ''
      },
      errors: false,
      valid: false,
      usernameRules: [
        (v: any) => !!v || 'Введите логин',
        (v: any) => v.length >= 3 || 'Логин должен быть не менее 3 символов'
      ],
      passwordRules: [
        (v: any) => !!v || 'Введите пароль',
        (v: any) => v.length >= 3 || 'Пароль должен быть не менее 3 символов'
      ],
      loading: false
    }
  },
 
  methods: {
    async handleSubmit () {
      try {        
        this.loading = true
        const data:UserLoginPost = {
          login: this.userLoginPost.username,
          password: this.userLoginPost.password
        }
        const result = await login(data)

        if (result.access_token) {
          localStorage.setItem('accessToken', result.access_token.toString())
        }
        if (result.refresh_token) {
          localStorage.setItem('refreshToken', result.refresh_token.toString())
        }
        if (result.token_type) {
          localStorage.setItem('tokenType', result.token_type.toString())
        }
        this.$emit('route', 'home')
      } finally {
        this.loading = false
      }
    }    
  },

  emits: ['route'],
  
  mounted() {
    if (localStorage.getItem('accessToken')) {
      this.$emit('route', 'home')
    }
  },

})
