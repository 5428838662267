
import PasswordPanel from "@/components/view/PasswordPanel.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    PasswordPanel: PasswordPanel
  },
  data() {
    return {
      title: 'This is general page'
    }
  },
  mounted() {
    console.log('home')
  },
})
