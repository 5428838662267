import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mp3filter" }
const _hoisted_2 = { class: "library-filter" }
const _hoisted_3 = { class: "library-filter-all" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "library-filter-values" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_audio_rating = _resolveComponent("audio-rating")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("tr", {
        onClick: [
          _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.selectValue(_ctx.allItem)), ["ctrl"])),
          _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.addValue(_ctx.allItem)), ["exact"]))
        ],
        class: _normalizeClass((_ctx.filter.values.find((el) => el.selected == true)) ? '' : 'selectedFilterValue')
      }, [
        _createElementVNode("td", _hoisted_3, _toDisplayString('Все ' + _ctx.filter.values.length), 1)
      ], 2),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filter.values, (item, index) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: index,
          onClick: [
            _withModifiers(($event: any) => (_ctx.selectValue(item)), ["ctrl"]),
            _withModifiers(($event: any) => (_ctx.addValue(item)), ["exact"])
          ],
          class: _normalizeClass((item.selected == true) ? 'selectedFilterValue' : '')
        }, [
          _createElementVNode("td", _hoisted_5, [
            (item.type == 'rating')
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                  _createVNode(_component_audio_rating, {
                    rating: item.value
                  }, null, 8, ["rating"])
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.value), 1))
          ])
        ], 10, _hoisted_4))
      }), 128))
    ])
  ]))
}