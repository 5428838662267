
import { Service } from "@/store/user/types";
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({    
    name: 'NavBar',
    props: ['userServices'],
    data() {
        return {
            mp3: false,
            window: {
                width: 0,
                height: 0
            }
        }
    },
    emits: ['route'],
    created() {
        this.window = store.getters['window/windowSize']
    },
    watch: { 
        userServices: function(newVal) {
            newVal.forEach((element: Service) => {
                if (element.service == '80a6d350-fc81-4116-a8e1-18e02d789980') {
                    this.mp3 = true
                }
            });
        }
    }
})
