

import { defineComponent } from "vue";

export default defineComponent({
    name: 'AudioRating',
    props: {
        rating: {
            type: Number,
            default: 0
        },
        fileId: {
            type: Number,
            default: undefined
        }
    },
    data() {
        return {
            ratingValue: 0
        }
    },
    watch: {
        rating: function () {
            this.ratingValue = this.rating
        }
    },
    methods: {
        getRatingWidth() {
            let ratingWidth = 'width: 0%'
            switch (this.rating) {
                case 252:
                    ratingWidth = 'width: 100%'
                    break;
                case 242:
                    ratingWidth = 'width: 90%'
                    break;
                case 196:
                    ratingWidth = 'width: 80%'
                    break;
                case 186:
                    ratingWidth = 'width: 70%'
                    break;
                case 128:
                    ratingWidth = 'width: 60%'
                    break;
                case 118:
                    ratingWidth = 'width: 50%'
                    break;
                case 64:
                    ratingWidth = 'width: 40%'
                    break;
                case 54:
                    ratingWidth = 'width: 30%'
                    break;
                case 23:
                    ratingWidth = 'width: 20%'
                    break;
                default:
                    break;
            }
            return ratingWidth
        }
    },
    mounted() {
        this.ratingValue = this.rating
    },
})
