
import { defineComponent } from "vue";
import { AudioFile, PlayedAudioFile } from "@/store/mp3player/types";
import { AudioFileGetPost, AudioFileSetCountPost, AudioFileSetRatingPost } from "@/store/mp3player/api-types";
import { getAudioFile, setCount } from "@/store/mp3player/api-requests";
import AudioRating from "./AudioRating.vue";
import AudioRatingActive from "./AudioRatingActive.vue";

enum State { 
    Nothing = 0, 
    Loading = 1,
    Loaded = 2,
    Play = 3, 
    Pause = 4
}

export default defineComponent({    
    components: { AudioRating, AudioRatingActive },
    name: 'AudioPlayer',
    props: ['playList', 'albumId'],
    emits: ['clearPlaylist', 'changeTrack', 'gift'],
    data() {
        return {
            playedAudioList: [] as Array<PlayedAudioFile>,
            timer: 0,
            timer2: 0,
            currentTime: 0,
            currentVolume: 1,
            currentId: 0,
            maxId: 0,
            progressInterval: 1000,
            clicks: 0,
            globalState: State.Nothing,
            playingTime: 0,
            playingId: 0,
            playingAlbumId: 0,
            playingRating: 0,
            sendStat: false,
            shuffle: false,
            loop: false
        }
    },
    computed: {
        currentTimeFormatted(): any {            
            if (this.currentTime) {
                return (this.currentTime/60 < 10 ? '0' : '') + Math.floor(this.currentTime/60) + ':' + (this.currentTime % 60 < 10 ? '0' : '') + Math.floor(this.currentTime % 60)
            }
            return '00:00'
        },  
        durationFormatted(): any {
            let findPlayedAudioFile = this.playedAudioList.find((el: PlayedAudioFile) => el.state == State.Play)            
            if (findPlayedAudioFile) {
                const duration = Number(findPlayedAudioFile.duration)
                return (duration/60 < 10 ? '0' : '') + Math.floor(duration/60) + ':' + (duration % 60 < 10 ? '0' : '') + Math.floor(duration % 60)
            }
            return '00:00'
        },
        progressPersent(): any {
            let findPlayedAudioFile = this.playedAudioList.find((el: PlayedAudioFile) => el.state == State.Play)            
            if (findPlayedAudioFile) {
                const duration = Number(findPlayedAudioFile.duration)
                return this.currentTime / (duration / 100)
            }
            return 0
        },
        volumePersent(): any {
            return this.currentVolume * 100
        }
    },
    watch : {
        playList: {
            handler(value) {
                this.playedAudioList.forEach((elementPlayed:PlayedAudioFile)  => {
                    if (!value.find((el: AudioFile) => el.id === elementPlayed.id)) {
                        this.playedAudioList = this.playedAudioList.filter((elF: PlayedAudioFile) => elF.id !== elementPlayed.id)
                    }
                });

                value.forEach((element: AudioFile) => {
                    if (!this.playedAudioList.find((el: PlayedAudioFile) => el.id === element.id)) {
                        this.maxId++
                        let newAudioListElement: PlayedAudioFile = {
                            id: element.id,
                            idPlaylist: this.maxId,
                            title: element.title,
                            duration: element.duration,
                            genre: element.genre,
                            dateAdd: element.dateAdd,
                            trackNo: element.trackNo,
                            rating: element.rating,
                            shareRating: element.shareRating,
                            lastPlayed: element.lastPlayed,
                            count: element.count,
                            selected: element.selected,
                            buffer: null,
                            state: 0
                        };
                        this.playedAudioList.push(newAudioListElement); 
                    }
                });
            },
            deep: true            
        },
        albumId: {
            handler(value) {
                this.playingAlbumId = value
            }            
        }
    },
    methods: {
        changeRating(value: AudioFileSetRatingPost) {
            let findPlayListAudioFile = this.playList.find((el: AudioFile) => el.id == value.id)
            let findPlayedAudioFile = this.playedAudioList.find((el: AudioFile) => el.id == value.id)
            if (findPlayListAudioFile) {
                findPlayListAudioFile.rating = value.rating
                this.playingRating = value.rating
            }
            if (findPlayedAudioFile) {
                findPlayedAudioFile.rating = value.rating
            }
        },
        changeTrack(audioFileId: number) {
            this.$emit('changeTrack', audioFileId)
        },
        volumeProgressbar(event: any) {
            var percent = event.offsetX / 200;
            const audioRef = this.$refs.audioRef
            if (audioRef instanceof Audio) { 
                if (percent > 1) percent = 1
                if (percent < 0) percent = 0
                audioRef.volume = percent   
                this.currentVolume = percent
            } 
        },
        audioProgressbar(event: any) {
            if (this.globalState !== State.Nothing) {
                var percent = event.offsetX / 400;
                const audioRef = this.$refs.audioRef
                if (audioRef instanceof Audio) {  
                    audioRef.currentTime = ((audioRef.duration / 100) * percent) * 100    
                    this.currentTime = ((audioRef.duration / 100) * percent) * 100    
                } 
            }   
        },
        loopHandle() {
            this.loop = !this.loop
        },
        shuffleHandle() {
            this.shuffle ? this.unshufflePlaylist : this.shufflePlaylist()
            this.shuffle = !this.shuffle
        },
        shufflePlaylist() {
            for (let i = this.playedAudioList.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1))
                if (j !== i) {
                    const temp = this.playedAudioList[i]
                    this.playedAudioList[i] = this.playedAudioList[j]
                    this.playedAudioList[j] = temp
                }                
            }
            let newIdPlaylist = 0
            this.playedAudioList.forEach((el: PlayedAudioFile) => {
                newIdPlaylist++
                el.idPlaylist = newIdPlaylist
            })
        },
        unshufflePlaylist() {
            let newIdPlaylist = 0
            this.playedAudioList.map((el: PlayedAudioFile) => el.idPlaylist = 0)
            this.playList.forEach((element: AudioFile) => {
                const findElement = this.playedAudioList.find((el: PlayedAudioFile) => el.id === element.id)
                if (findElement) {
                    newIdPlaylist++
                    findElement.idPlaylist = newIdPlaylist
                }                
            });
        },
        clearPlaylist() {
            this.maxId = 0
            this.stop()
            this.$emit('clearPlaylist')            
        },
        gift() {
            this.$emit('gift') 
        },
        selectAudioTrack(audioFile: AudioFile) {
            this.playedAudioList.map((el: PlayedAudioFile) => el.selected = false)
            let findPlayedAudioFile = this.playedAudioList.find((el: PlayedAudioFile) => el.id == audioFile.id)
            if (findPlayedAudioFile) {                
                findPlayedAudioFile.selected = true   
                this.clicks++;   
                if (this.clicks === 1) {
                    this.timer = setTimeout( () => {
                        this.clicks = 0                                                    
                    }, 300);
                } else if (this.clicks === 2) {   
                    clearTimeout(this.timer)
                    if (this.currentId !== 0) {
                        this.currentId = findPlayedAudioFile.idPlaylist
                    }                    
                    this.clicks = 0
                    this.stop()
                    this.play(this.currentId)
                } else {
                    clearTimeout(this.timer)
                    this.clicks = 0
                }  
            }
                       
        },
        checkStateFile (audioFileId: number) {
            let classes = ''
            if (this.playedAudioList.find((el: PlayedAudioFile) => el.selected === true && el.id === audioFileId)) {
                classes += ' library-audio-list-table-tr-selected '
            }
            if (this.playedAudioList.find((el: PlayedAudioFile) => el.state === State.Play && el.id === audioFileId)) {
                classes += ' library-audio-list-table-tr-played '
            }
            return classes
        },
        checkPlayedFile(audioFileId: number) {
            return this.playedAudioList.find((el: PlayedAudioFile) => el.state === State.Play && el.id === audioFileId) ? true : false
        },
        getAudioFileLastDuration(duration: number | null): string {
            if (duration) {
                return Math.floor(duration/60) + ':' + (duration % 60 < 10 ? '0' : '') + (duration % 60)
            }
            return ''
        },        
        back() {
            console.log('back')
            let nextId = this.currentId - 1
            if (nextId < 1) {
                nextId = this.maxId
            }
            this.play(nextId)
        },
        forward() {
            console.log('forward')
            let nextId = this.currentId + 1
            if (nextId > this.maxId) {
                nextId = 1
                if (this.loop) {
                    this.play(nextId)
                } else {
                    this.stop()
                }
            } else {
                this.play(nextId)
            }           
        },
        stop() {
            console.log('stop')
            this.globalState = State.Nothing            
            this.currentTime = 0
            this.playingTime = 0
            this.sendStat = false
            window.clearInterval(this.timer2)
            const audioRef = this.$refs.audioRef
            if (audioRef instanceof Audio) {
                audioRef.pause()
            }
        },
        pause() {
            console.log('pause')
            this.globalState = State.Pause
            const audioRef = this.$refs.audioRef
            if (audioRef instanceof Audio) {
                audioRef.pause()
            }
        },
        play(playId = 0) {  
            console.log('play')
            if (this.globalState === State.Pause) {
                const audioRef = this.$refs.audioRef
                if (audioRef instanceof Audio) {
                    this.globalState = State.Play
                    audioRef.play()
                }
            } else {
                window.clearInterval(this.timer2)
                this.playingId = 0
                this.playingRating = 0
                this.playingTime = 0
                this.currentTime = 0
                this.sendStat = false
                if (this.playedAudioList.length > 0) {
                    let findPlayedAudioFile = null
                    if (playId !== 0) {                        
                        findPlayedAudioFile = this.playedAudioList.find((el: PlayedAudioFile) => el.idPlaylist === playId)
                    } else {
                        findPlayedAudioFile = this.playedAudioList.find((el: PlayedAudioFile) => el.selected === true)
                    }                    
                    if (findPlayedAudioFile) {
                        this.playingId = findPlayedAudioFile.id
                        this.playingRating = findPlayedAudioFile.rating
                        this.changeTrack(findPlayedAudioFile.id)
                        /*this.playedAudioList.map((el: PlayedAudioFile) => {
                            if (el.idPlaylist < (this.playingId - 2) || el.idPlaylist > (this.playingId + 2)) {
                                el.state = State.Nothing
                                el.buffer = null
                            }                            
                        })*/
                        this.playedAudioList.map((el: PlayedAudioFile) => {
                            if (el.buffer !== null) {
                                el.state = State.Loaded
                            } else {
                                el.state = State.Nothing
                            }
                        })                        
                        const audioRef = this.$refs.audioRef    
                        if (findPlayedAudioFile.state === State.Loaded && typeof findPlayedAudioFile.buffer === 'string') {
                            this.globalState = State.Play  
                            findPlayedAudioFile.state = State.Play  
                            this.currentId = findPlayedAudioFile.idPlaylist   
                            if (this.currentId !== 0) {
                                let nextId2 = this.currentId + 1
                                if (nextId2 > this.maxId) {
                                    nextId2 = 1
                                }
                                let findNextPlayedAudioFile = this.playedAudioList.find((el: PlayedAudioFile) => el.idPlaylist === nextId2)
                                if (findNextPlayedAudioFile) {
                                    if (findNextPlayedAudioFile.state === State.Nothing) {
                                        findNextPlayedAudioFile.state = State.Loading
                                        this.loadFiles(findNextPlayedAudioFile)
                                    }
                                }                
                            }  

                            if (audioRef instanceof Audio) {
                                audioRef.setAttribute('src', findPlayedAudioFile.buffer)
                                audioRef.play().then(() => {                                    
                                    //console.log('timer2', this.timer2)
                                    this.$nextTick(() => { 
                                        this.timer2 = window.setInterval(
                                            this.playing,
                                            this.progressInterval
                                        )
                                    })
                                    //console.log('timer2-2', this.timer2)
                                }).catch(() => console.log('Ошибка 2'))
                            }
                        } else if (findPlayedAudioFile.state === State.Nothing) {
                            this.globalState = State.Loading
                            findPlayedAudioFile.state = State.Loading
                            this.loadFiles(findPlayedAudioFile)
                        }                    
                    }
                } 
            }                      
        },                
        async loadFiles(playedAudioFile: PlayedAudioFile) {
            const params: AudioFileGetPost = {
                id: playedAudioFile.id
            }
            const result = await getAudioFile(params).then((response : any) => {        
                const blob = new Blob([response.data], {type: 'audio/mp3'});
                const urlBlob = URL.createObjectURL(blob);
                playedAudioFile.buffer = urlBlob                                
            }).then(() => {
                if (playedAudioFile.state === State.Loading) {
                    playedAudioFile.state = State.Loaded
                    if (this.globalState === State.Loading) {
                        this.play(playedAudioFile.idPlaylist)   
                    }                    
                }
            })
        },
        playing() {
            //console.log('playingTime ', this.playingTime)
            if (this.globalState === State.Play || this.globalState === State.Pause) {
                const audioRef = this.$refs.audioRef
                if (audioRef instanceof Audio) {
                    this.currentTime = audioRef.currentTime
                    this.currentVolume = audioRef.volume

                    if (this.globalState === State.Play) {
                        this.playingTime = this.playingTime + 1
                        if (this.playingTime > ((audioRef.duration / 100) * 50) && !this.sendStat) {
                            this.sendStat = true
                            console.log('Отправляем статистику', this.playingId)
                            console.log('playingTime', this.playingTime)
                            console.log('duration', audioRef.duration)
                            let findPlayListAudioFile = this.playList.find((el: AudioFile) => el.id == this.playingId)
                            if (findPlayListAudioFile) {
                                const params: AudioFileSetCountPost = {
                                    id: this.playingId
                                }
                                setCount(params)
                                .then((response : any) => {        
                                    findPlayListAudioFile.count++  
                                    findPlayListAudioFile.lastPlayed = response.timestamp
                                })                                
                            }                            
                        }
                    }

                    if (audioRef.currentTime === audioRef.duration) {
                        this.forward()
                    }
                } 
            } else {
                this.currentTime = 0
                this.playingTime = 0
                this.sendStat = false
            }                                 
        }
    },
    mounted() {
        const audioRef = this.$refs.audioRef
        if (audioRef instanceof Audio) {
            this.currentVolume = 0.75
            audioRef.volume = this.currentVolume
        }
    }
})
