import { AxiosError } from 'axios'

type E = {
  code: any
  message: any
}

export type ServerError = {
  error: E
}

export type ApiResponse<T> = {
  data: T
}

export const parseAxiosError = (e: AxiosError): ServerError => {
  if (e.response) {
    return {
      error: {
        code: e.response,
        message: e.response.data
      }
    }
  }

  return {
    error: {
      code: 500,
      message: 'Internal Server Error'
    }
  }
}


