import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_WINDOW_SIZE } from './mutations'
import { WindowSize } from './types'
import { WindowState } from "./state"
import { RootState } from '../state'

type WindowContext = ActionContext<WindowState, RootState>

const getDefaultState = () => {
  return {
    windowSize: {
      width: 0,
      height: 0
    }
  }
}

const initialState: WindowState = getDefaultState()

const getters: GetterTree<WindowState, RootState> = {
  windowSize(state: WindowState): WindowSize {
    return state.windowSize
  }
}

const mutations: MutationTree<WindowState> = {
  [SET_WINDOW_SIZE](state: WindowState, windowSize: WindowSize) {
    state.windowSize = windowSize
  }
}

const actions: ActionTree<WindowState, RootState> = {
  async setWindowSize({ commit }: WindowContext, windowSize): Promise<any> {
    try {
      commit(SET_WINDOW_SIZE, windowSize)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const window = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
