import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../assets/logo.png'
import _imports_1 from '../../assets/profile.jpg'


const _hoisted_1 = { class: "head" }
const _hoisted_2 = { class: "head-logo" }
const _hoisted_3 = {
  key: 0,
  class: "head-menu"
}
const _hoisted_4 = {
  key: 1,
  class: "head-menu"
}
const _hoisted_5 = { class: "head-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          class: "img",
          alt: "Logo",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('route', 'home'))),
          src: _imports_0
        })
      ]),
      (_ctx.mp3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('route', 'mp3player')))
            }, "MP3")
          ]))
        : _createCommentVNode("", true),
      (_ctx.mp3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", null, _toDisplayString(_ctx.window.width + 'x' + _ctx.window.height), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("img", {
          class: "img",
          alt: "Logo",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('route', 'login'))),
          src: _imports_1
        })
      ])
    ])
  ]))
}