import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Album } from './types'
import { AudioFileGetPost, AudioFileSetCountPost, AudioFileSetRatingPost } from './api-types'

const routeGetLibrary = '/getLibrary'
const routeGetAudioFile = '/getAudioFile'
const routeSetCount = '/setCount'
const routeSetRating = '/setRating'

export const getLibrary = async (): Promise<Album[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse = await axios.post(routeGetLibrary)
  return result.data.data
}

export const getAudioFile = async (data: AudioFileGetPost): Promise<any> => {
  const axiosRequestConfig: AxiosRequestConfig = {
    responseType: 'arraybuffer'
  }
  const result: AxiosResponse = await axios.post(routeGetAudioFile, data, axiosRequestConfig)
  return result
}

export const setCount = async (data: AudioFileSetCountPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse = await axios.post(routeSetCount, data)
  return result.data.data
}

export const setRating = async (data: AudioFileSetRatingPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse = await axios.post(routeSetRating, data)
  return result.data.data
}