

import { defineComponent } from "vue";
import { AudioFileSetRatingPost } from "@/store/mp3player/api-types";
import { setRating } from "@/store/mp3player/api-requests";

export default defineComponent({
    name: 'AudioRatingActive',
    emits: ['setRating'],
    props: {
        rating: {
            type: Number,
            default: 0
        },
        fileId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            ratingValue: 0,
            ratingValueActive: 0,
            sendRating: false
        }
    },
    watch : {
        rating: function() {
            this.ratingValue = this.rating
        }
    },
    methods: {
        async sendRatingHandle(value: number) {        
            if (!this.sendRating && this.fileId !== 0) {
                this.sendRating = true                
                
                const audioFileSetRatingPost: AudioFileSetRatingPost = {
                    id: this.fileId,
                    rating: value
                }
                
                setRating(audioFileSetRatingPost)
                .then((response : any) => {        
                    this.$emit('setRating', audioFileSetRatingPost)
                    this.sendRating = false
                    this.ratingValueActive = 0
                })
            }
        },
        onmouseover(value: number) {
            if (!this.sendRating && this.fileId !== 0) {
                this.ratingValueActive = value
            }
        },
        onmouseRatingLeave() {
            if (!this.sendRating && this.fileId !== 0) {
                this.ratingValueActive = 0
            }            
        },
        getRatingWidth() {
            let ratingWidth = 'width: 0%'
            switch (this.rating) {
                case 252:
                    ratingWidth = 'width: 100%'
                    break;
                case 242:
                    ratingWidth = 'width: 90%'
                    break;
                case 196:
                    ratingWidth = 'width: 80%'
                    break;
                case 186:
                    ratingWidth = 'width: 70%'
                    break;
                case 128:
                    ratingWidth = 'width: 60%'
                    break;
                case 118:
                    ratingWidth = 'width: 50%'
                    break;
                case 64:
                    ratingWidth = 'width: 40%'
                    break;
                case 54:
                    ratingWidth = 'width: 30%'
                    break;
                case 23:
                    ratingWidth = 'width: 20%'
                    break;
                default:
                    break;
            }
            return ratingWidth
        },
        getRatingActiveWidth() {
            let ratingWidth = 'width: 0%'
            switch (this.ratingValueActive) {
                case 252:
                    ratingWidth = 'width: 100%'
                    break;
                case 242:
                    ratingWidth = 'width: 90%'
                    break;
                case 196:
                    ratingWidth = 'width: 80%'
                    break;
                case 186:
                    ratingWidth = 'width: 70%'
                    break;
                case 128:
                    ratingWidth = 'width: 60%'
                    break;
                case 118:
                    ratingWidth = 'width: 50%'
                    break;
                case 64:
                    ratingWidth = 'width: 40%'
                    break;
                case 54:
                    ratingWidth = 'width: 30%'
                    break;
                case 23:
                    ratingWidth = 'width: 20%'
                    break;
                default:
                    break;
            }
            return ratingWidth
        }
    },
    mounted() {
        this.ratingValue = this.rating      
    },
})
