import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_USER, CLEAR_USER } from '@/store/user/mutations'
import { Service, User } from '@/store/user/types'
import { UserState } from "@/store/user/state"
import { parseAxiosError } from '@/services/api'
import { getUserInfo } from '@/store/user/api-requests'
import { RootState } from '../state'

type UserContext = ActionContext<UserState, RootState>

const getDefaultState = () => {
  return {
    user: {
      name: 'unknown',
      email: 'unknown',
      services: []
    },
    lastUpdate: null
  }
}

const initialState: UserState = getDefaultState()

const getters: GetterTree<UserState, RootState> = {
  user(state: UserState): UserState {
    return state
  },
  userServices(state: UserState): Service[] | undefined {
    return state.user?.services
  },
  lastUpdate(state: UserState): Date | null {
    return state.lastUpdate
  }
}

const mutations: MutationTree<UserState> = {
  [SET_USER](state: UserState, user: User) {
    state.user = user
    state.lastUpdate = new Date(new Date().setHours(new Date().getHours() + 1))
  },
  [CLEAR_USER](state: UserState) {
    Object.assign(state, getDefaultState())
  }
}

const actions: ActionTree<UserState, RootState> = {
  async loadUser({ commit }: UserContext): Promise<any> {
    try {
      const user = await getUserInfo()
      commit(SET_USER, user)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    }
  },

  async clear({ commit }: UserContext): Promise<void> {
    try {
      console.log('clear')
      commit(CLEAR_USER)
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      window.location.href = 'https://www.vdmar.ru/'
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    }
  },
}

export const user = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
