import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'
import { UserLoginPost } from './api-types'
import { User, UserToken } from './types'

const routeLogin = '/login'
const routeGetUserInfo = '/getUserInfo'

export const login = async (data: UserLoginPost): Promise<UserToken> => {
  const result: AxiosResponse = await axios.post(routeLogin, data)
  return result.data.data
}

export const getUserInfo = async (): Promise<User> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse = await axios.post(routeGetUserInfo)
  return result.data.data
}
